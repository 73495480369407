.footer {
    width: 100%;
    padding: 50px 5%;
    padding-bottom: 20px;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: white;
}

.footer .line {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, calc(100% / 4));
    gap: 0px;
    padding-right: 5%;
}

.footer .flex :is(a, p) {
    font-size: 14px;
    font-weight: lighter;
}

.footer a::before {
    transition: .5s;
    content: "";
    position: absolute;
    height: 0%;
    width: 3px;
    border-radius: 20px;
    top: 0;
    left: 0;
    background-color: black;
}

.footer a:hover:before {
    height: 100%;
}

.footer a:hover {
    padding-left: 10px;
}

.footer :is(.flex, .logo) a:hover {
    padding-left: 0px;
}

.footer :is(.flex, .logo) a::before {
    content: none;
}

.footer .flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer .line div {
    width: fit-content;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 90%;
}

.footer div img {
    max-width: 80%;
}

.footer .social {
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
}

.footer .social svg {
    width: 25px;
    height: auto;
}

.footer div h6 {
    font-weight: bold;
    position: relative;
    margin-bottom: 15px;
    width: fit-content;
}

.footer div .redes {
    margin-top: 20px;
}

.footer div h6::before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 4px;
    background-color: black;
    bottom: -4px;
}

.footer div a {
    font-size: 15px;
    position: relative;
    transition: .5s;
    width: fit-content;
}

.footer .flex a {
    width: fit-content;
}

.footer select {
    width: 90%;
    background-color: rgba(0, 0, 0, 0.50);
    border-radius: 4px;
    padding: 8px 10px;
    color: white;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .footer {
        gap: 20px;
    }
    .footer .line {
        grid-template-columns: repeat(1, calc(100% / 1));
        padding-right: 5%;
        row-gap: 20px;
    }
    .footer .flex {
        flex-direction: column;
        align-items: start;
        justify-content: start;
        gap: 20px;
    }
}