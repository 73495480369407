header>img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;
    filter: brightness(0.5);
}

@media screen and (max-width: 600px) {
    header {
        flex-direction: column;
        align-items: flex-start;
        padding: 35% 5% !important;
    }
    header>div {
        width: 100% !important;
    }
    header :is(h1, h5) {
        text-align: left;
        width: 100%;
    }
}