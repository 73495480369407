.slide {
    transition: .5s;
    position: relative;
    transition: opacity 0.5s ease;
    padding-bottom: 40px;
}

.trusted {
    width: 100%;
    display: grid;
    grid-template-columns: 60% 40%;
    padding: 0 15%;
    overflow: hidden;
}

.trusted .text {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.trusted .slidesDiv {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    gap: 30px;
}

.slidesDiv::before,
.slidesDiv::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20px;
    z-index: 1;
    pointer-events: none;
}

.slidesDiv::before {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.slidesDiv::after {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.multipleRows {
    width: 100%;
    height: 100%;
}

.multipleRows .logo {
    transition: .5s;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-left: 10px;
    height: 50px;
}

.multipleRows img {
    max-width: 100%;
    max-height: 50px;
}

.slide .slick-list {
    transition: .5s;
    overflow: visible;
}

.slide .slick-active.slick-center {
    transition: .5s;
    transform: scale(1.05);
    opacity: 1;
}

.slide .slick-slide {
    transition: .5s;
    transform: scale(.8);
    opacity: .9;
}

.slide .slideDivs {
    transition: .5s;
    background-color: rgba(0, 0, 0, 0.85);
    width: 90%;
    height: 50%;
    border-radius: 10px;
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 20px;
}

.slide .slideDivs :is(p, a) {
    color: #ccc;
    font-size: medium;
}

.slide .slideDivs a {
    transition: .5s;
    color: white;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: left;
    margin-top: auto;
    margin-bottom: 0;
}

.slide .slideDivs a:hover {
    gap: 20px;
}

.slide .slideDivs h4 {
    color: white;
    font-weight: bold;
}

.slide .slideDivs img {
    aspect-ratio: initial;
    width: auto;
    height: 50px;
}

.btnEmpresas {
    position: absolute;
    right: 10%;
    top: 50%;
    width: 20%;
    aspect-ratio: 1/1;
    transform: translateY(-50%);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
}

.btnEmpresas .wrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 50%;
    border: 5px solid rgba(255, 255, 255, 0.7);
    display: flex;
    flex-direction: column;
}

.btnEmpresas span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 5px solid;
    border-radius: 50%;
}

.btnEmpresas span:nth-child(1) {
    width: 125%;
    height: 125%;
    border-color: rgba(255, 255, 255, 0.5);
}

.btnEmpresas span:nth-child(2) {
    width: 155%;
    height: 155%;
    border-color: rgba(255, 255, 255, 0.3);
}

.btnEmpresas span:nth-child(3) {
    width: 185%;
    height: 185%;
    border-color: rgba(255, 255, 255, 0.2);
}

.btnEmpresas span:nth-child(4) {
    width: 210%;
    height: 210%;
    border-color: rgba(255, 255, 255, 0.1);
}

.btnEmpresas span:nth-child(5) {
    width: 240%;
    height: 240%;
    border-color: rgba(255, 255, 255, 0.05);
}

.btnEmpresas .heroSlide {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.btnEmpresas .slick-track {
    transition: .5s;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 600px) {
    .trusted {
        grid-template-columns: 100%;
        padding: 0 5%;
        gap: 50px;
    }
    .btnEmpresas {
        right: unset;
        top: unset;
        bottom: 15%;
        left: 50%;
        transform: translateX(-50%);
        width: 70%;
    }
    .btnEmpresas .wrap {
        border: 5px solid rgba(255, 255, 255, 0.2);
    }
    .btnEmpresas span:nth-child(1) {
        border-color: rgba(255, 255, 255, 0.15);
    }
    .btnEmpresas span:nth-child(2) {
        border-color: rgba(255, 255, 255, 0.1);
    }
    .btnEmpresas span:nth-child(3) {
        border-color: rgba(255, 255, 255, 0.05);
    }
    .btnEmpresas span:nth-child(4) {
        border-color: rgba(255, 255, 255, 0.01);
    }
    .btnEmpresas span:nth-child(5) {
        border-color: rgba(255, 255, 255, 0.005);
    }
    .trusted .text {
        width: 100%;
    }
    .slick-track {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .slide .slideDivs {
        width: 100%;
    }
    .home {
        display: flex;
        flex-direction: column;
        gap: 200px;
    }
}