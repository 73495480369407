.empresaHome header {
    height: auto;
    min-height: 100vh;
    padding-bottom: 10vh;
}

.empresaHome .companiesText {
    z-index: 20;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 10vh;
}

.empresaHome .companiesText .empresaHomeGrid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, calc(100% / 3));
    row-gap: 30px;
}

.empresaHome .companiesText .empresaHomeGrid a {
    transition: .5s;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    gap: 10px;
    margin-right: 10px;
    border-radius: 10px;
    --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 1);
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    background-color: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(5px);
    cursor: pointer;
    overflow: hidden;
}

.empresaHome .companiesText .empresaHomeGrid a:hover {
    backdrop-filter: blur(15px);
}

.empresaHome .companiesText .empresaHomeGrid a :is(p, h5, span) {
    color: white;
}

.empresaHome .companiesText .empresaHomeGrid a h5 {
    font-weight: bold;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.empresaHome .companiesText .empresaHomeGrid a h5 img {
    width: 50px;
    height: auto;
    min-height: auto;
    border-radius: 4px;
    overflow: hidden;
    aspect-ratio: 1/1;
}

.empresaHome .companiesText .empresaHomeGrid a span {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: start;
    justify-content: start;
    margin-top: 30px
}

.empresaHome .companiesText .empresaHomeGrid a span p {
    padding: 5px 15px;
    border: 2px solid white;
    border-radius: 40px;
    font-size: 15px;
}

@media screen and (max-width: 600px) {
    .empresaHome .companiesText .empresaHomeGrid {
        grid-template-columns: repeat(1, 100%);
    }
    .empresaHome .companiesText .empresaHomeGrid a {
        width: 100%;
    }
}