.offer {
    gap: 50px;
}

.offer .offerHero {
    height: 80vh;
    position: relative;
}

.offer .offerHero .textOfferHero {
    position: absolute;
    z-index: 20;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.offer .array {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 80vw;
    margin: auto;
    border-left: 4px solid black;
    gap: 150px;
}

.offer .array section {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 10px;
    min-height: 30vh;
    width: 100%;
}

.offer .array section h1 {
    transition: .5s;
    margin-bottom: 20px;
    padding: 0 5%;
    position: relative;
    width: 100%;
    text-transform: uppercase;
}

.offer .array section h1 span {
    transition: .5s;
    content: '';
    position: absolute;
    height: 15px;
    width: 15px;
    top: 50%;
    transform: translateY(-50%);
    left: -10px;
    background-color: black;
    border-radius: 50%;
}

.offer .array .visible h1 span {
    height: 60px;
    width: 60px;
    left: -32px;
}

.offer .array .visible h1 {
    font-size: 90px;
}

.offer .array .visible p {
    transition: .7s all;
    font-size: 20px;
}

.offer .array section p {
    padding: 0 5%;
}

.offer .array section h1 {
    transition: .5s;
    opacity: 20%;
}

.offer .array section p {
    opacity: 15%;
}

.offer .array .visible :is(p, h1) {
    opacity: 100%;
}

@media screen and (max-width: 600px) {
    .offer .offerHero .textOfferHero :is(h1, h5) {
        text-align: left;
        width: 90%;
    }
    .offer .array section :is(h1, p) {
        padding-left: 10%;
        transition: 1s;
    }
    .offer .array {
        width: 90vw;
    }
    .offer .array h1 {
        font-size: 30px !important;
    }
    .offer .array .visible h1 span {
        height: 30px;
        width: 30px;
        left: -17px;
    }
}