.empresa {
    gap: 150px;
}

.empresa .empresaHero {
    height: 60vh;
}

.empresa .empresaHero img {
    max-height: 70%;
}

.empresa .empresaHero .heroComponent {
    width: 80%;
    min-height: 20%;
    background-color: rgba(255, 255, 255, 0.2);
    z-index: 20;
    position: absolute;
    top: 37%;
    left: 10%;
    padding: 30px 50px;
    border-radius: 30px;
    --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 15px;
    overflow: hidden;
    backdrop-filter: blur(8px);
}

.empresa .empresaHero .heroComponent h2 {
    font-weight: bold;
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 10px;
}

.empresa .empresaHero .heroComponent :is(h2, h6, p) {
    color: white;
}

.empresa .empresaHero .heroComponent h2 svg {
    width: 17px;
    height: auto;
    margin-top: 5px;
}

.empresa .empresaHero .heroComponent .tags {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    flex-wrap: wrap;
}

.empresa .empresaHero .heroComponent .tags p {
    padding: 5px 17px;
    border: 2px solid;
    border-radius: 20px;
    font-weight: bold;
    font-size: 15px;
}

.empresa .oferta {
    width: 100vw;
    height: auto;
    padding: 90px 10%;
    display: grid;
    grid-template-columns: repeat(3, calc(100% / 3));
    gap: 0;
    align-items: start;
    row-gap: 50px;
    position: relative;
    background: transparent;
}

.empresa .oferta .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 15px 5px rgba(0, 0, 0, 0.5);
    filter: brightness(.8);
}

.empresa .oferta .col {
    z-index: 3;
    transition: .5s;
    width: 90%;
    background-color: white;
    border-radius: 20px;
    padding: 30px;
    box-shadow: 0px -10px 10px rgb(0 0 0 / 0.10), -0px 10px 10px rgb(0 0 0 / 0.15), 10px 0px 10px rgb(0 0 0 / 0.15), -10px -0px 10px rgb(0 0 0 / 0.15);
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 10px;
    margin: 0 auto;
    height: 100%;
    max-height: 90px;
    overflow: hidden;
    cursor: pointer;
}

.empresa .oferta .col:hover {
    max-height: 1000px;
}

.empresa .oferta .col h5 {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-height: 40px;
    margin-bottom: 30px;
    text-overflow: ellipsis;
}

.empresa .oferta .col h5 svg {
    width: 30px;
    height: auto;
}

.empresa .oferta .col p {
    padding-left: 25px;
    position: relative;
}

.empresa .oferta .col p::before {
    position: absolute;
    content: '';
    top: 13px;
    left: 15px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    height: 4px;
    width: 4px;
    background-color: black;
}

@media screen and (max-width: 600px) {
    .empresa .empresaHero .heroComponent {
        width: 95%;
        left: 2.5%;
        border: 5%;
        padding: 30px;
        border-radius: 20px;
        top: 20%;
    }
    .empresa .empresaHero .heroComponent .tags p {
        padding: 5px 10px;
        font-size: 12px;
    }
    .empresa .oferta {
        padding: 90px 2.5%;
        grid-template-columns: repeat(1, 100%);
    }
}