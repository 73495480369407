@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    overflow-y: auto;
    font-family: 'Aceh', sans-serif;
    padding: 0;
    scroll-behavior: smooth;
    text-rendering: optimizeLegibility;
}

main {
    height: auto;
    width: 100vw;
    display: flex;
    flex-direction: column;
    gap: 200px;
    min-height: 100vh;
    background-color: white;
    overflow: hidden;
}

h1 {
    font-size: 60px;
    color: black;
    font-weight: bold;
}

h2 {
    font-size: 40px;
}

h3 {
    font-size: 34px;
}

h4 {
    font-size: 30px;
}

h5 {
    font-size: 24px;
}

h6 {
    font-size: 20px;
}

p,
a {
    font-size: 18px;
}

h1,
h2,
h3,
h4,
p,
span,
label,
button,
a {
    color: black;
}

html,
body,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
div,
video {
    margin: 0;
    padding: 0;
    border: 0;
}

@media screen and (max-width: 600px) {
    main {
        gap: 100px;
    }
    h1 {
        font-size: 45px !important;
    }
    h2 {
        font-size: 40px !important;
    }
    h3 {
        font-size: 34px !important;
    }
    h4 {
        font-size: 30px !important;
    }
    h5 {
        font-size: 24px;
    }
    h6 {
        font-size: 20px;
    }
    p,
    a {
        font-size: 18px;
    }
}