#btnScrool {
    transition: .5s;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 36.37px;
    z-index: 900;
    display: flex;
    align-items: center;
    justify-content: center;
}

#btnScrool:hover {
    width: 60px;
    height: 54.55px;
}

#btnScrool svg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 29.84px;
}

#btnScrool svg:nth-child(2) {
    top: 0;
    bottom: unset;
    right: 0;
    left: unset;
}

#btnScrool p {
    transition: .5s;
    font-size: 0px;
    font-weight: bolder;
}

#btnScrool:hover p {
    font-size: 10px;
    font-weight: bolder;
}