.about .aboutHeroText {
    width: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
}

.about .historia {
    width: 100%;
    min-height: 50vh;
    position: relative;
    overflow: hidden;
    padding: 5%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.about .historia :is(h1, p) {
    z-index: 2;
    color: white;
    max-width: 100%;
}

.about .historia h1 {
    margin-bottom: 15px;
}

.about .historia img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    filter: grayscale(1) brightness(.4);
    background-position: center;
    background-size: cover;
    z-index: 1;
    object-fit: cover;
}

.about .goals {
    padding: 0 15%;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 60% 40%;
}

.about .goals .textsGoals {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 90%;
}

.about .goals .images {
    display: grid;
    grid-template-columns: auto auto;
    padding: 40px;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.about .goals .images img {
    max-height: 100px;
}

.about .team {
    width: 100%;
    padding: 0 10%;
    display: flex;
    flex-direction: column;
}

.about .team .members {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, calc(100% / 5));
    gap: 10px;
}

.about .team .members img {
    background-color: #ccc;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.about .team .members figure {
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 200px;
    max-width: 230px;
    margin-top: 80px;
    align-self: center;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
}

.about .team .members figure figcaption {
    display: flex;
    flex-direction: column;
    gap: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    padding-top: 40px;
    width: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.about .team .members figure figcaption p {
    font-weight: bold;
    text-transform: capitalize;
    font-size: 18px;
    color: white;
}

@media screen and (max-width: 600px) {
    .about .historia :is(h1, p) {
        max-width: 100%;
    }
    .about .historia img {
        height: 100%;
        width: auto;
    }
    .about .goals {
        padding: 0;
        grid-template-columns: 90%;
        align-items: center;
        justify-content: center;
    }
    .about .goals .images {
        padding: 0;
        margin-top: 20px;
    }
    .about .quote h4 {
        font-size: 20px !important;
    }
    .about .quote h6 {
        font-size: 15px;
    }
    .about .team .members {
        grid-template-columns: repeat(2, calc(100% / 2));
    }
    .about .team .members figcaption p {
        font-size: 15px;
    }
    .about .team h1 {
        font-size: 35px;
        margin-bottom: 15px;
    }
    .about .team .members figure {
        margin-top: 30px;
        min-width: unset;
    }
}