.section2 {
    min-width: 50%;
    max-width: 80%;
    width: auto;
    padding: 6% 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    align-items: center;
    justify-content: center;
    align-self: center;
}

.section2 :is(p, h1, h3) {
    color: black;
}

.section2 img {
    width: 100px;
    position: absolute;
    bottom: 0%;
    left: -5%;
}

.section2 img:nth-child(2) {
    bottom: unset;
    top: 5%;
    left: unset;
    right: -5%;
}

@media screen and (max-width: 600px) {
    .section2 img {
        width: 60px;
    }
    .section2 {
        padding: 20% 0;
        align-items: flex-start;
    }
}