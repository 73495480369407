.sectionRoot {
    display: grid;
    grid-template-columns: 50% 50%;
}

.sectionRoot :is(h1, p, h5) {
    color: black;
}

.sectionRoot .img {
    display: flex;
    background-color: rgba(0, 0, 0, 0.0);
    padding: 3% 3% 3% 0;
    width: 90%;
    border-radius: 0 10px 10px 0;
}

.sectionRoot .img img {
    width: 100%;
    border-radius: 0 4px 4px 0;
    background-color: #ccc;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sectionRoot .texts {
    padding: 5% 15% 5% 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: start;
}

@media screen and (max-width: 600px) {
    .sectionRoot {
        grid-template-columns: 90%;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
    .sectionRoot :is(.texts, .img) {
        padding: 0;
    }
    .sectionRoot .img {
        display: flex;
        padding: 0;
        width: 100%;
    }
    .sectionRoot .img img {
        border-radius: 10px;
    }
}