.navBar {
    transition: .7s;
    height: auto;
    max-height: 66px;
    display: grid;
    grid-template-columns: 30% repeat(3, calc(40%/3)) 30%;
    width: 100%;
    padding: 15px 7%;
    background-color: rgba(255, 255, 255, 0);
    gap: 10px;
    align-items: start;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    overflow: hidden;
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.navBar.scrolled {
    transition: .7s;
    background-color: rgba(255, 255, 255, 0.85);
}

.navBar.active {
    transition: .7s;
    max-height: 100vh;
    background-color: rgba(255, 255, 255, 1);
}

.navBar :is(p, a, svg) {
    color: white;
    fill: white;
}

:is(.navBar.active, .navBar.scrolled) :is(p, a, svg) {
    color: black;
    fill: black;
}

:is(.navBar.active, .navBar.scrolled) .col.contact .title {
    border: 2px solid black;
}

.navBar .col.contact .title {
    border: 2px solid white;
}

.navBar svg {
    height: 30px;
    width: auto;
    aspect-ratio: initial;
    margin-top: 5px;
}

.navBar .col {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: left;
    gap: 15px;
    width: 100%;
}

.navBar .title {
    transition: .5s;
    font-size: large;
    font-weight: bold;
    position: relative;
    text-align: left;
    width: 100%;
    padding: 5px;
    padding-left: 0px;
    margin-bottom: 17px;
}

.navBar a {
    font-size: 15px;
}

.navBar .col a {
    position: relative;
    transition: .5s;
}

.navBar .col.contact {
    align-items: end;
}

.navBar .col.contact .title {
    transition: .5s;
    text-align: right;
    width: fit-content;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 40px;
}

.navBar.navBar.active .col.contact .title {
    padding-right: 0;
}

.navBar:hover .col.contact .title {
    border: 2px solid transparent;
}

.navBar .col.center a::before {
    transition: .5s;
    content: "";
    position: absolute;
    height: 0%;
    width: 3px;
    border-radius: 20px;
    top: 0;
    left: 0;
    background-color: black;
}

.navBar .col.center .title::before {
    content: unset;
}

.navBar .col.center a:hover:before {
    height: 100%;
}

.navBar .col.center a:hover {
    padding-left: 10px;
}

.navBar .col.center .title:hover {
    padding-left: 5px;
}

.navBar .col.contact a:hover {
    transition: .5s;
    font-weight: bold;
}

@media screen and (max-width: 1200px) {
    .navBar {
        max-height: auto;
        grid-template-columns: 90%;
        padding: 20px;
        width: 100vw;
    }
    .navBar .title {
        padding: 0;
        margin-bottom: 0;
        margin-top: 10px;
    }
    .navBar .col.contact {
        align-items: start;
    }
    .navBar .col.contact .title {
        text-align: left;
        border-radius: none;
        padding: 0;
    }
    .navBar.active {
        overflow-y: auto;
    }
    .navBar a:nth-child(1) {
        width: 85vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .navBar div:nth-child(1) {
        width: 87vw;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
     :is(.navBar.active, .navBar.scrolled) .col.contact .title {
        border: 2px solid transparent;
    }
}